<template lang="pug">
    #photo-capture-prompt.video-step-dialog.is-fullscreen
      .modal-text
        icon(v-if="iconPath" :data="iconPath")
        h1 {{ prompt }}
      .modal-footer
        button.button.cancel-btn(@click="cancel") {{ cancelBtnText }}
        button.button.action-btn(@click="performAction") {{ actionBtnText }}
  </template>

<script>
export default {
  props: {
    prompt: { type: String, required: true },
    icon: { type: String, required: true },
    onAction: { type: Function, required: true },
    cancelBtnText: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    actionBtnText: {
      type: String,
      required: false,
      default: 'Yes',
    },
  },

  data() {
    return {
      iconPath: null,
    }
  },

  computed: {},

  watch: {},

  created() {
    this.iconPath = require('@icon/' + this.icon)
  },

  methods: {
    performAction,
    cancel,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function performAction() {
  this.onAction()
  this.$modal.close('PhotoCapturePrompt')
}

function cancel() {
  this.$modal.close('PhotoCapturePrompt')
}
</script>
